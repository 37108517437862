export const PREFIX_KEY = "riviera_nuragica_";

// Local Storage KEY
export const LS_TOKEN_KEY = PREFIX_KEY + "token_user";
export const LS_TOKEN_CONSUMER_KEY = PREFIX_KEY + "token_consumer_user";
export const LS_USER_KEY = PREFIX_KEY + "user_json";
export const LS_USER_CONSUMER_KEY = PREFIX_KEY + "user_consumer_json";

export const LS_DEFAULT_LANGUAGE_KEY = PREFIX_KEY + "language";
export const LS_DEFAULT_CURRENCY_KEY = PREFIX_KEY + "currency";

// URLs
export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API;
export const URL_API_USER = "users/";
export const URL_RESERVATION = "reservation/";
export const URL_SITES = "sites/";
export const URL_TRANSACTION = "transaction/";
export const URL_FEE_LOGS = "fee-log/";
export const URL_STATEMENT = "statement/";
export const URL_ANALYTICS = "analytics/";
export const URL_ANALYTICS_EXPORT = "analytics/export/";

export const URL_API_RELATIVE_OPENING_HOUR = "opening-hours"

export const URL_API_USER_LOGIN = URL_API_USER + "auth_token/";
export const URL_API_USER_ME = URL_API_USER + "me/";
export const URL_INVOICE_NUMBER = URL_API_USER_ME + "invoice-number/";



export const URL_CURRENCIES = BASE_URL_API + "consumer/currencies/";
export const URL_CASH_REPORT_DAY = BASE_URL_API + "users/me/cash_report/";
export const URL_ESTIMATE_RESERVATION = BASE_URL_API + URL_RESERVATION+ "estimate/";
export const URL_BOOKING_RESERVATION = BASE_URL_API + "consumer/reservation/";

export const URL_API_PAYOUTS = "stripe/payouts/";
export const URL_API_RELATIVE_TRANSACTIONS = "transactions";


export const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
export const STRIPE_APPLICATION_FEE_PERCENT = 5;
export const VAT_PERCENT = 22;
export const STRIPE_FIXED_TAX = 0.30;

export const TIMEZONE_DEFAULT = 'Europe/Rome';
export const CURRENCY_DEFAULT = 'EUR';
export const COUNTRY_DEFAULT = 'IT';


// ENUMs
export const MONTH_LIST = [
    {value: 0, name: "Gennaio"},
    {value: 1, name: "Febbraio"},
    {value: 2, name: "Marzo"},
    {value: 3, name: "Aprile"},
    {value: 4, name: "Maggio"},
    {value: 5, name: "Giugno"},
    {value: 6, name: "Luglio"},
    {value: 7, name: "Agosto"},
    {value: 8, name: "Settembre"},
    {value: 9, name: "Ottobre"},
    {value: 10, name: "Novembre"},
    {value: 11, name: "Dicembre"},
]

// Options


export const LIMIT_RECORDS_API = 20;
export const DEFAULT_LANGUAGE = "it";
