import Vue from "vue";
import VueRouter from "vue-router";
import { StorageHelper } from "../common/storageHelper";
import {Utils} from "../common/utils";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue")
    },
    {
        path: "/reservations",
        name: "reservations",
        meta: { requiresAuth: true },
        alias: "/",
        component: () => import("../views/Reservations.vue")
    },
    {
        path: "/booking",
        name: "booking",
        meta: { requiresAuth: true },
        alias: "/",
        component: () => import("../views/Booking.vue")
    },
    {
        path: "/cash-report",
        name: "cash-report",
        meta: { requiresAuth: true },
        component: () => import("../views/CashReport.vue")
    },
    {
        path: "/transactions",
        name: "transactions",
        meta: { requiresAuth: true },
        component: () => import("../views/Transactions.vue")
    },
    {
        path: "/transactions-date",
        name: "transactions_date",
        meta: { requiresAuth: true },
        component: () => import("../views/TransactionsDate.vue")
    },
    {
        path: "/fee-log",
        name: "fee-log",
        meta: { requiresAuth: true },
        component: () => import("../views/FeeLog.vue")
    },
    {
        path: "/analytics",
        name: "analytics",
        meta: { requiresAuth: true },
        component: () => import("../views/Analytics.vue")
    },
    {
        path: "/reservations/:reservation_id",
        name: "reservation_detail",
        meta: { requiresAuth: true },
        component: () => import("../views/ReservationDetail.vue")
    },
    {
        path: "/site_hours",
        name: "site_hours",
        meta: { requiresAuth: true },
        component: () => import("../views/Sites.vue")
    },
    {
        path: "/site_hours/:site_id",
        name: "site_open_hours",
        meta: { requiresAuth: true },
        component: () => import("../views/SiteHours.vue")
    },
    {
        path: "/reservations/:reservation_id/ticket",
        name: "ticket",
        meta: { requiresAuth: true },
        component: () => import("../views/Ticket.vue")
    },
    {
        path: "/booking-online",
        name: "booking_online",
        component: () => import("../views/BookingOnline.vue")
    },
    {
        path: "/payouts",
        name: "payouts",
        meta: { requiresAuth: true },
        component: () => import("../views/Payouts.vue")
    },
    {
        path: "/payouts/:payout_id",
        name: "payout_detail",
        meta: { requiresAuth: true },
        component: () => import("../views/PayoutDetail.vue")
    },

    {
        path: "/statement",
        name: "statement",
        meta: { requiresAuth: true },
        component: () => import("../views/Statement.vue")
    },
];

const routerManager = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes
});

routerManager.beforeEach((to, from, next) => {
    const authUser = StorageHelper.getUser();
    const token = StorageHelper.getToken();
    if (to.meta.requiresAuth) {
        if (authUser && token) {
            if (to.path === "/") {
                next({name : Utils.getRedirectPageNameByUser(authUser)})
            } else {
                next();
            }
        } else {
            next({ name: "login" });
        }
    } else {
        if (to.name === "login" && authUser && token) {
            next({name : Utils.getRedirectPageNameByUser(authUser)})
        } else {
            next();
        }
    }
});

export default routerManager;
