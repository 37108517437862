import {StringsHelper} from "./stringsHelper"


export const RESERVATION_CHANNEL = {
    OFFLINE: 1,
    ONLINE_RN: 2,
    ONLINE_GESECO: 3
};

export const RESERVATION_STATES = {
    PENDING: 1,
    COMPLETED: 2,
    CANCELED: 3,
    PAYMENT_REQUIRED: 4
};

export const RATES_PRICE = {
    PRICE_AMOUNT_FULL: 4,
    PRICE_AMOUNT_STUDENT_NOT_RESIDENT: 1,
    PRICE_GRATIS: 0,
    PRICE_RESIDENT_ADULTS: "0 / €2",
};

export const RATES = {
    ADULT: {
        value: 0,
        price: RATES_PRICE.PRICE_AMOUNT_FULL
    },
    CHILD: {
        value: 1,
        price: RATES_PRICE.PRICE_GRATIS
    },
    DISABLED: {
        value: 2,
        price: RATES_PRICE.PRICE_GRATIS
    },
    RESIDENT_ADULT: {
        value: 3,
        price: RATES_PRICE.PRICE_RESIDENT_ADULTS
    },
    RESIDENT_CHILD: {
        value: 4,
        price: RATES_PRICE.PRICE_GRATIS
    },
    RESIDENT_STUDENT: {
        value: 5,
        price: RATES_PRICE.PRICE_GRATIS
    },
    NOT_RESIDENT_STUDENT: {
        value: 6,
        price: RATES_PRICE.PRICE_AMOUNT_STUDENT_NOT_RESIDENT
    },
    OTHER_FREE: {
        value: 7,
        price: RATES_PRICE.PRICE_GRATIS
    },

    PROFESSOR: {
        value: 8,
        price: RATES_PRICE.PRICE_GRATIS
    }
};

export const USER_ROLE = {
    TICKET: 1,
    ADMIN: 2,
    ACCOUNTING: 3,
};


export const PAYMENT_MODES = {
    CASH : 1,
    POS : 2,
    CREDIT_CARD : 3,
    TRANSFER : 4,
    TO_PAY : 5
};

export const CREATORS = {
    CODDU_ECCHJU : 3,
    LI_LOLGHI : 4,
    MALCHITTU : 5,
    LA_PRISGIONA : 6,
    LI_MURI : 7,
    IL_MUSEO: 11
};

export const DATES = {
    TODAY : "today",
    YESTERDAY : "yesterday",
    THIS_WEEK : "this-week",
    LAST_WEEK : "last-week",
    THIS_MONTH : "this-month",
    LAST_MONTH : "last-month",
    THIS_YEAR : "this-year",

};

export const DOCUMENT_TYPES = {
    TICKET : 1,
    INVOICE : 2,
};

export const PRODUCTS = {
     ALBUCCIU: 0,
     MORU: 1,
     MALCHITTU: 2,
     PRIGIONA: 3,
     CODDU_ECCHJU: 4,
     LI_MURI: 5,
     LI_LOLGHI: 6,
     MUSEO: 7
};

export const STATUS_STRIPE_PAYOUNT = {
    PAID : "paid",
    PENDING : "pending",
    TRANSIT : "in_transit",
    CANCELED : "canceled",
    FAILED : "falied"
};


export const PAYMENT_MODE_WITHOUT_CREDIT_CARD_OPTIONS = () => { return [
    { "name": StringsHelper.getPaymentModeSimple(PAYMENT_MODES.CASH), "value": PAYMENT_MODES.CASH},
    { "name": StringsHelper.getPaymentModeSimple(PAYMENT_MODES.POS), "value": PAYMENT_MODES.POS},
    { "name": StringsHelper.getPaymentModeSimple(PAYMENT_MODES.TRANSFER), "value": PAYMENT_MODES.TRANSFER},
    { "name": StringsHelper.getPaymentModeSimple(PAYMENT_MODES.TO_PAY), "value": PAYMENT_MODES.TO_PAY},
]};



export const PAYMENT_MODE_OPTIONS = () => {
    let payment_methods = PAYMENT_MODE_WITHOUT_CREDIT_CARD_OPTIONS();
    payment_methods.push(
        { "name": StringsHelper.getPaymentModeSimple(PAYMENT_MODES.CREDIT_CARD), "value": PAYMENT_MODES.CREDIT_CARD}
    );
    return payment_methods
};

export const CREATORS_OPTIONS = () => { return [
    { "name": StringsHelper.getCreatorSimple(CREATORS.CODDU_ECCHJU), "value": CREATORS.CODDU_ECCHJU},
    { "name": StringsHelper.getCreatorSimple(CREATORS.LI_LOLGHI), "value": CREATORS.LI_LOLGHI},
    { "name": StringsHelper.getCreatorSimple(CREATORS.MALCHITTU), "value": CREATORS.MALCHITTU},
    { "name": StringsHelper.getCreatorSimple(CREATORS.LA_PRISGIONA), "value": CREATORS.LA_PRISGIONA},
    { "name": StringsHelper.getCreatorSimple(CREATORS.LI_MURI), "value": CREATORS.LI_MURI},
    { "name": StringsHelper.getCreatorSimple(CREATORS.IL_MUSEO), "value": CREATORS.IL_MUSEO},

]};

export const TRANSACTIONS_DATE_OPTIONS = () => { return [
    { "name": StringsHelper.getTransactionDateSimple(DATES.TODAY), "value": DATES.TODAY},
    { "name": StringsHelper.getTransactionDateSimple(DATES.YESTERDAY), "value": DATES.YESTERDAY},
    { "name": StringsHelper.getTransactionDateSimple(DATES.LAST_WEEK), "value": DATES.LAST_WEEK},
    { "name": StringsHelper.getTransactionDateSimple(DATES.THIS_MONTH), "value": DATES.THIS_MONTH},
    { "name": StringsHelper.getTransactionDateSimple(DATES.LAST_MONTH), "value": DATES.LAST_MONTH},
    { "name": StringsHelper.getTransactionDateSimple(DATES.THIS_YEAR), "value": DATES.THIS_YEAR},
]};

export const RESERVATIONS_DATE_OPTIONS = () => { return [
    { "name": StringsHelper.getReservationDateSimple(DATES.TODAY), "value": DATES.TODAY},
    { "name": StringsHelper.getReservationDateSimple(DATES.YESTERDAY), "value": DATES.YESTERDAY},
    { "name": StringsHelper.getReservationDateSimple(DATES.THIS_WEEK), "value": DATES.THIS_WEEK},
    { "name": StringsHelper.getReservationDateSimple(DATES.LAST_WEEK), "value": DATES.LAST_WEEK},
    { "name": StringsHelper.getReservationDateSimple(DATES.THIS_MONTH), "value": DATES.THIS_MONTH},
    { "name": StringsHelper.getReservationDateSimple(DATES.LAST_MONTH), "value": DATES.LAST_MONTH},
    { "name": StringsHelper.getReservationDateSimple(DATES.THIS_YEAR), "value": DATES.THIS_YEAR},
]};

export const FEE_LOGS_DATE_OPTIONS = () => { return [
    { "name": StringsHelper.getTransactionDateSimple(DATES.THIS_MONTH), "value": DATES.THIS_MONTH}, 
    { "name": StringsHelper.getTransactionDateSimple(DATES.LAST_MONTH), "value": DATES.LAST_MONTH},
    { "name": StringsHelper.getTransactionDateSimple(DATES.THIS_YEAR), "value": DATES.THIS_YEAR},
]};


export const PRODUCTS_OPTIONS = () => { return [
    { "name": StringsHelper.getProduct(PRODUCTS.ALBUCCIU), "value": PRODUCTS.ALBUCCIU},
    { "name": StringsHelper.getProduct(PRODUCTS.MORU), "value": PRODUCTS.MORU},
    { "name": StringsHelper.getProduct(PRODUCTS.MALCHITTU), "value": PRODUCTS.MALCHITTU},
    { "name": StringsHelper.getProduct(PRODUCTS.PRIGIONA), "value": PRODUCTS.PRIGIONA},
    { "name": StringsHelper.getProduct(PRODUCTS.CODDU_ECCHJU), "value": PRODUCTS.CODDU_ECCHJU},
    { "name": StringsHelper.getProduct(PRODUCTS.LI_MURI), "value": PRODUCTS.LI_MURI},
    { "name": StringsHelper.getProduct(PRODUCTS.LI_LOLGHI), "value": PRODUCTS.LI_LOLGHI},
    { "name": StringsHelper.getProduct(PRODUCTS.MUSEO), "value": PRODUCTS.MUSEO},
]};

export const DOCUMENT_TYPE_OPTIONS = () => { return [
    { "name": StringsHelper.getDocumentType(DOCUMENT_TYPES.TICKET), "value": DOCUMENT_TYPES.TICKET},
    { "name": StringsHelper.getDocumentType(DOCUMENT_TYPES.INVOICE), "value": DOCUMENT_TYPES.INVOICE},
]};


export const RATE_OTHER_FREE_INDEX = 3;

export const RATES_OPTIONS = () => { return [
    { "name": StringsHelper.getRate(RATES.ADULT.value), "value": RATES.ADULT.value, "price": RATES.ADULT.price},
    { "name": StringsHelper.getRate(RATES.CHILD.value), "value": RATES.CHILD.value, "price": RATES.CHILD.price},
    { "name": StringsHelper.getRate(RATES.DISABLED.value), "value": RATES.DISABLED.value, "price": RATES.DISABLED.price},
    { "name": StringsHelper.getRate(RATES.OTHER_FREE.value), "value": RATES.OTHER_FREE.value, "price": RATES.OTHER_FREE.price},
    { "name": "--"},
    { "name": StringsHelper.getRate(RATES.RESIDENT_ADULT.value), "value": RATES.RESIDENT_ADULT.value, "price": RATES.RESIDENT_ADULT.price},
    { "name": StringsHelper.getRate(RATES.RESIDENT_CHILD.value), "value": RATES.RESIDENT_CHILD.value, "price": RATES.RESIDENT_CHILD.price},
    { "name": "--"},
    { "name": StringsHelper.getRate(RATES.RESIDENT_STUDENT.value), "value": RATES.RESIDENT_STUDENT.value, "price": RATES.RESIDENT_STUDENT.price},
    { "name": StringsHelper.getRate(RATES.NOT_RESIDENT_STUDENT.value), "value": RATES.NOT_RESIDENT_STUDENT.value, "price": RATES.NOT_RESIDENT_STUDENT.price},
]};
