import i18n from "../i18n";
import { Settings } from "luxon";
import moment from 'moment-timezone'

export class LocaleHelper {
    static setLanguage(language) {
        i18n.locale = language;
        Settings.defaultLocale = language;
    }

    static getLanguage() {
        return i18n.locale;
    }

    static setTimezone(timezone) {
        moment.tz.setDefault(timezone)
    }

    static getTimezone() {
        return moment.tz.guess();
    }

}
