import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "booking_online",
        component: () => import("../views/BookingOnline.vue"),
    },
    {
        path: "/:lang/",
        name: "booking_online",
        component: () => import("../views/BookingOnline.vue"),
    },
];

const routerBooking = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "active", // active class for *exact* links.
    routes
});

export default routerBooking;
