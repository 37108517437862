import * as Keys from "./keys"
import {COUNTRIES, COUNTRIES_EN} from "./countries";
import {STATUS_STRIPE_PAYOUNT} from "./keys";
import i18n from "../i18n";

export class StringsHelper {

    static getProduct(product) {
        switch (product) {
            case Keys.PRODUCTS.ALBUCCIU:
                return "Nuraghe Albucciu";
            case Keys.PRODUCTS.MORU:
                return "Tomba dei Giganti Moru";
            case Keys.PRODUCTS.MALCHITTU:
                return "Tempietto Malchittu";
            case Keys.PRODUCTS.PRIGIONA:
                return "Nuraghe La Prisgiona";
            case Keys.PRODUCTS.CODDU_ECCHJU:
                return "Tomba dei Giganti Coddu Ecchju";
            case Keys.PRODUCTS.LI_MURI:
                return "Necropoli Li Muri";
            case Keys.PRODUCTS.LI_LOLGHI:
                return "Tomba dei Giganti Li Lolghi";
            case Keys.PRODUCTS.MUSEO:
                return "Museo Michele Ruzittu di Arzachena"
        }
    }

    static getDocumentType(type) {
        switch (type) {
            case Keys.DOCUMENT_TYPES.TICKET:
                return "Biglietto";
            case Keys.DOCUMENT_TYPES.INVOICE:
                return "Fattura";
            default:
                return "Tutti"
        }
    }

    static getRate(rate) {
        return this.getRateInternal(rate, i18n.locale)
    }

    static getRateEn(rate) {
        return this.getRateInternal(rate, 'en')
    }

    static getRateInternal(rate, locale) {
        switch (rate) {
            case Keys.RATES.ADULT.value:
                return i18n.t('common.adult', locale);
            case Keys.RATES.CHILD.value:
                return i18n.t('common.child', locale);
            case Keys.RATES.DISABLED.value:
                return i18n.t('common.disabled', locale);
            case Keys.RATES.RESIDENT_ADULT.value:
                return i18n.t('common.adult_resident', locale);
            case Keys.RATES.RESIDENT_CHILD.value:
                return i18n.t('common.child_resident', locale);
            case Keys.RATES.RESIDENT_STUDENT.value:
                return i18n.t('common.resident_student', locale);
            case Keys.RATES.NOT_RESIDENT_STUDENT.value:
                return i18n.t('common.non_resident_student', locale);
            case Keys.RATES.OTHER_FREE.value:
                return i18n.t('common.other_free_rate', locale);
            case Keys.RATES.PROFESSOR.value:
                return i18n.t('common.teacher_tour_leader', locale);
        }
    }

    static getDay(day) {
        switch (day) {
            case 1:
                return "Lunedì";
            case 2:
                return "Martedì";
            case 3:
                return "Mercoledì";
            case 4:
                return "Giovedì";
            case 5:
                return "Venerdì";
            case 6:
                return "Sabato";
            case 7:
                return "Domenica";
        }
    }

    static getOfferta(reservation) {
        if (reservation.count_products === 1) {
            return StringsHelper.getProduct(reservation.reservation_item.product);
        } else {
            return "Combo (" + reservation.count_products + " siti)"
        }
    }
    static getChannel(channel) {
        switch(channel){
            case Keys.RESERVATION_CHANNEL.OFFLINE:
                return "Biglietteria";
            case Keys.RESERVATION_CHANNEL.ONLINE_RN:
                return "Online Riviera Nuragica";
            case Keys.RESERVATION_CHANNEL.ONLINE_GESECO:
                return "Online Geseco";
        }
    }
    static getReservationState(state) {
        switch (state) {
            case Keys.RESERVATION_STATES.PENDING:
                return "In attesa";
            case Keys.RESERVATION_STATES.COMPLETED:
                return "Completata";
            case Keys.RESERVATION_STATES.CANCELED:
                return "Annullata";
            case Keys.RESERVATION_STATES.PAYMENT_REQUIRED:
                return "Pagamento Richiesto";
        }
    }

    static getPaymentModeSimple(payment_mode){
        switch (payment_mode) {
            case Keys.PAYMENT_MODES.CASH:
                return "Contanti";
            case Keys.PAYMENT_MODES.POS:
                return "Pos";
            case Keys.PAYMENT_MODES.CREDIT_CARD:
                return "Carta di credito";
            case Keys.PAYMENT_MODES.TRANSFER:
                return "Bonifico";
            case Keys.PAYMENT_MODES.TO_PAY:
                return "Da pagare";
            default:
                return "Tutti"
        }
    }


    static getTransactionDateSimple(date){
        return "Transazioni" + StringsHelper.getGeneticDateSimple(date)
    }

    static getReservationDateSimple(date) {
        return "Prenotazioni" + StringsHelper.getGeneticDateSimple(date)
    }

    static getGeneticDateSimple(date) {
        switch (date) {
            case Keys.DATES.TODAY:
                return " di oggi";
            case Keys.DATES.YESTERDAY:
                return " di ieri";
            case Keys.DATES.THIS_WEEK:
                return " di questa settimana";
            case Keys.DATES.LAST_WEEK:
                return " della scorsa settimana";
            case Keys.DATES.THIS_MONTH:
                return " di questo mese";
            case Keys.DATES.LAST_MONTH:
                return " dello scorso mese";
            case Keys.DATES.THIS_YEAR:
                return " di quest'anno";
            default:
                return " di oggi"
        }
    }

    static getCreatorSimple(creator){
        
        switch (creator) {
            case Keys.CREATORS.CODDU_ECCHJU:
                return "Biglietteria Coddu Ecchju";
            case Keys.CREATORS.LI_LOLGHI:
                return "Biglietteria Li Lolghi";
            case Keys.CREATORS.MALCHITTU:
                return "Biglietteria Malchittu";
            case Keys.CREATORS.LA_PRISGIONA:
                return "Biglietteria La Prisgiona";
            case Keys.CREATORS.LI_MURI:
                return "Biglietteria Li Muri";
            case Keys.CREATORS.IL_MUSEO:
                return "Biglietteria Museo";
            default:
                return "Tutti gli utenti"
        }
    }

    static getPaymentMode(payment_mode){

        switch (payment_mode) {
            case Keys.PAYMENT_MODES.CASH:
                return "Pagato (" + StringsHelper.getPaymentModeSimple(payment_mode) +")";
            case Keys.PAYMENT_MODES.POS:
                return "Pagato (" + StringsHelper.getPaymentModeSimple(payment_mode) +")";
            case Keys.PAYMENT_MODES.CREDIT_CARD:
                return "Pagato (" + StringsHelper.getPaymentModeSimple(payment_mode) +")";
            case Keys.PAYMENT_MODES.TRANSFER:
                return StringsHelper.getPaymentModeSimple(payment_mode);
            case Keys.PAYMENT_MODES.TO_PAY:
                return StringsHelper.getPaymentModeSimple(payment_mode);
            default:
                return "Nessuno";
        }
    }


    static getClientStringNameFromReservation(reservation) {
        if (reservation.customer_first_name && reservation.customer_last_name) {
            return reservation.customer_first_name + " " + reservation.customer_last_name;
        }
        return "NA";
    }

    static getStringCountryFromISO(isoCountry) {
        return COUNTRIES[isoCountry];
    }

    static getStringCountryFromISOEN(isoCountry) {
        return COUNTRIES_EN[isoCountry];
    }

    static getStringOrNA(string) {
        if (string) {
            return string;
        }
        return 'NA'
    }

    static getStatusPayout = (state) => {
        switch (state) {
            case STATUS_STRIPE_PAYOUNT.PAID:
                return "Pagato";
            case STATUS_STRIPE_PAYOUNT.CANCELED:
                return "Cancellato";
            case STATUS_STRIPE_PAYOUNT.FAILED:
                return "Fallito";
            case STATUS_STRIPE_PAYOUNT.TRANSIT:
                return "In transito";
            case STATUS_STRIPE_PAYOUNT.PENDING:
                return "In attesa";
            default:
                return "In attesa";
        }
    };
}
