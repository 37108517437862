import Vue from "vue";
import App from "./App.vue";
import titleMixin from "./common/titleMixin";
import routerManager from "@/router/routerManager";
import i18n from "./i18n";
import localeMixin from "./common/localeMixin";
import routerBooking from "./router/routerBooking";

Vue.config.productionTip = false;

const host = window.location.host;
const parts = host.split(".");

const router = () => {
    console.log(parts)
    if (parts.includes(process.env.VUE_APP_MANAGER_URL)) {
        return routerManager;
    } else if (parts.includes(process.env.VUE_APP_BOOKING_URL)) {
        return routerBooking;
    } else {
        return routerManager;
    }
};

Vue.mixin(titleMixin);
Vue.mixin(localeMixin);

new Vue({
    router: router(),
    i18n,
    render: h => h(App)
}).$mount("#app");
