export var COUNTRIES = {};
COUNTRIES.IT = "Italia";
COUNTRIES.DE = "Germania";
COUNTRIES.FR = "Francia";
COUNTRIES.GB = "Regno Unito / Gran Bretagna";
COUNTRIES.BE = "Belgio";
COUNTRIES.NL = "Olanda";
COUNTRIES.PT = "Portogallo";
COUNTRIES.CZ = "Repubblica Ceca";
COUNTRIES.ES = "Spagna";
COUNTRIES.CH = "Svizzera";
COUNTRIES.RU = "Russia";
COUNTRIES.CN = "Cina";
COUNTRIES.JP = "Giappone";
COUNTRIES.A = null;
COUNTRIES.AF = "Afghanistan";
COUNTRIES.AL = "Albania";
COUNTRIES.DZ = "Algeria";
COUNTRIES.AD = "Andorra";
COUNTRIES.AO = "Angola";
COUNTRIES.AI = "Anguilla";
COUNTRIES.AQ = "Antartide";
COUNTRIES.AG = "Antigua e Barbuda";
COUNTRIES.AN = "Antille Olandesi";
COUNTRIES.SA = "Arabia Saudita";
COUNTRIES.AR = "Argentina";
COUNTRIES.AM = "Armenia";
COUNTRIES.AW = "Aruba";
COUNTRIES.AU = "Australia";
COUNTRIES.AT = "Austria";
COUNTRIES.AZ = "Azerbaijan";
COUNTRIES.B = null;
COUNTRIES.BS = "Bahamas";
COUNTRIES.BH = "Bahrain";
COUNTRIES.BD = "Bangladesh";
COUNTRIES.BB = "Barbados";
COUNTRIES.BZ = "Belize";
COUNTRIES.BJ = "Benin";
COUNTRIES.BM = "Bermuda";
COUNTRIES.BY = "Bielorussia";
COUNTRIES.BT = "Bhutan";
COUNTRIES.BO = "Bolivia";
COUNTRIES.BA = "Bosnia Erzegovina";
COUNTRIES.BW = "Botswana";
COUNTRIES.BR = "Brasile";
COUNTRIES.BN = "Brunei Darussalam";
COUNTRIES.BG = "Bulgaria";
COUNTRIES.BF = "Burkina Faso";
COUNTRIES.BI = "Burundi";
COUNTRIES.C = null;
COUNTRIES.KH = "Cambogia";
COUNTRIES.CM = "Camerun";
COUNTRIES.CA = "Canada";
COUNTRIES.CV = "Capo Verde";
COUNTRIES.TD = "Ciad";
COUNTRIES.CL = "Cile";
COUNTRIES.CY = "Cipro";
COUNTRIES.VA = "Citta del Vaticano";
COUNTRIES.CO = "Colombia";
COUNTRIES.KM = "Comore";
COUNTRIES.KP = "Corea del Nord";
COUNTRIES.KR = "Corea del Sud";
COUNTRIES.CR = "Costa Rica";
COUNTRIES.CI = "Costa d'Avorio";
COUNTRIES.HR = "Croazia";
COUNTRIES.CU = "Cuba";
COUNTRIES.D = null;
COUNTRIES.DK = "Danimarca";
COUNTRIES.DM = "Dominica";
COUNTRIES.E = null;
COUNTRIES.EC = "Ecuador";
COUNTRIES.EG = "Egitto";
COUNTRIES.IE = "Eire";
COUNTRIES.SV = "El Salvador";
COUNTRIES.AE = "Emirati Arabi Uniti";
COUNTRIES.ER = "Eritrea";
COUNTRIES.EE = "Estonia";
COUNTRIES.ET = "Etiopia";
COUNTRIES.F = null;
COUNTRIES.FJ = "Fiji";
COUNTRIES.PH = "Filippine";
COUNTRIES.FI = "Finlandia";
COUNTRIES.G = null;
COUNTRIES.GA = "Gabon";
COUNTRIES.GM = "Gambia";
COUNTRIES.GE = "Georgia";
COUNTRIES.GH = "Ghana";
COUNTRIES.JM = "Giamaica";
COUNTRIES.GI = "Gibilterra";
COUNTRIES.DJ = "Gibuti";
COUNTRIES.JO = "Giordania";
COUNTRIES.GR = "Grecia";
COUNTRIES.GD = "Grenada";
COUNTRIES.GL = "Groenlandia";
COUNTRIES.GP = "Guadalupa";
COUNTRIES.GU = "Guam";
COUNTRIES.GT = "Guatemala";
COUNTRIES.GG = "Guernsey";
COUNTRIES.GN = "Guinea";
COUNTRIES.GW = "Guinea-Bissau";
COUNTRIES.GQ = "Guinea Equatoriale";
COUNTRIES.GY = "Guyana";
COUNTRIES.GF = "Guyana Francese";
COUNTRIES.H = null;
COUNTRIES.HT = "Haiti";
COUNTRIES.HN = "Honduras";
COUNTRIES.HK = "Hong Kong";
COUNTRIES.I = null;
COUNTRIES.IN = "India";
COUNTRIES.ID = "Indonesia";
COUNTRIES.IR = "Iran";
COUNTRIES.IQ = "Iraq";
COUNTRIES.BV = "Isola di Bouvet";
COUNTRIES.CX = "Isola di Natale";
COUNTRIES.HM = "Isola Heard e Isole McDonald";
COUNTRIES.KY = "Isole Cayman";
COUNTRIES.CC = "Isole Cocos";
COUNTRIES.CK = "Isole Cook";
COUNTRIES.FK = "Isole Falkland";
COUNTRIES.FO = "Isole Faroe";
COUNTRIES.MH = "Isole Marshall";
COUNTRIES.MP = "Isole Marianne Settentrionali";
COUNTRIES.UM = "Isole Minori degli Stati Uniti d'America";
COUNTRIES.NF = "Isola Norfolk";
COUNTRIES.SB = "Isole Solomon";
COUNTRIES.TC = "Isole Turks e Caicos";
COUNTRIES.VI = "Isole Vergini Americane";
COUNTRIES.VG = "Isole Vergini Britanniche";
COUNTRIES.IL = "Israele";
COUNTRIES.IS = "Islanda";
COUNTRIES.K = null;
COUNTRIES.KZ = "Kazakhistan";
COUNTRIES.KE = "Kenya";
COUNTRIES.KG = "Kirghizistan";
COUNTRIES.KI = "Kiribati";
COUNTRIES.XK = "Kosovo";
COUNTRIES.KW = "Kuwait";
COUNTRIES.L = null;
COUNTRIES.LA = "Laos";
COUNTRIES.LV = "Lettonia";
COUNTRIES.LS = "Lesotho";
COUNTRIES.LB = "Libano";
COUNTRIES.LR = "Liberia";
COUNTRIES.LY = "Libia";
COUNTRIES.LI = "Liechtenstein";
COUNTRIES.LT = "Lituania";
COUNTRIES.LU = "Lussemburgo";
COUNTRIES.M = null;
COUNTRIES.MO = "Macao";
COUNTRIES.MK = "Macedonia";
COUNTRIES.MG = "Madagascar";
COUNTRIES.MW = "Malawi";
COUNTRIES.MV = "Maldive";
COUNTRIES.MY = "Malesia";
COUNTRIES.ML = "Mali";
COUNTRIES.MT = "Malta";
COUNTRIES.MA = "Marocco";
COUNTRIES.MQ = "Martinica";
COUNTRIES.MR = "Mauritania";
COUNTRIES.MU = "Maurizius";
COUNTRIES.YT = "Mayotte";
COUNTRIES.MX = "Messico";
COUNTRIES.MD = "Moldavia";
COUNTRIES.MC = "Monaco";
COUNTRIES.MN = "Mongolia";
COUNTRIES.ME = "Montenegro";
COUNTRIES.MS = "Montserrat";
COUNTRIES.MZ = "Mozambico";
COUNTRIES.MM = "Myanmar";
COUNTRIES.N = null;
COUNTRIES.NA = "Namibia";
COUNTRIES.NR = "Nauru";
COUNTRIES.NP = "Nepal";
COUNTRIES.NI = "Nicaragua";
COUNTRIES.NE = "Niger";
COUNTRIES.NG = "Nigeria";
COUNTRIES.NU = "Niue";
COUNTRIES.NO = "Norvegia";
COUNTRIES.NC = "Nuova Caledonia";
COUNTRIES.NZ = "Nuova Zelanda";
COUNTRIES.O = null;
COUNTRIES.OM = "Oman";
COUNTRIES.P = null;
COUNTRIES.PK = "Pakistan";
COUNTRIES.PW = "Palau";
COUNTRIES.PA = "Panama";
COUNTRIES.PG = "Papua Nuova Guinea";
COUNTRIES.PY = "Paraguay";
COUNTRIES.PE = "Peru";
COUNTRIES.PN = "Pitcairn";
COUNTRIES.PF = "Polinesia Francese";
COUNTRIES.PL = "Polonia";
COUNTRIES.PR = "Porto Rico";
COUNTRIES.Q = null;
COUNTRIES.QA = "Qatar";
COUNTRIES.R = null;
COUNTRIES.CF = "Repubblica Centroafricana";
COUNTRIES.CG = "Repubblica del Congo";
COUNTRIES.CD = "Repubblica Democratica del Congo";
COUNTRIES.DO = "Repubblica Dominicana";
COUNTRIES.RE = "Reunion";
COUNTRIES.RO = "Romania";
COUNTRIES.RW = "Ruanda";
COUNTRIES.S = null;
COUNTRIES.EH = "Sahara Occidentale";
COUNTRIES.KN = "Saint Kitts e Nevis";
COUNTRIES.PM = "Saint Pierre e Miquelon";
COUNTRIES.VC = "Saint Vincent e Grenadine";
COUNTRIES.WS = "Samoa";
COUNTRIES.AS = "Samoa Americane";
COUNTRIES.SM = "San Marino";
COUNTRIES.SH = "Sant'Elena";
COUNTRIES.LC = "Santa Lucia";
COUNTRIES.ST = "Sao Tome e Principe";
COUNTRIES.SN = "Senegal";
COUNTRIES.SC = "Seychelles";
COUNTRIES.RS = "Serbia";
COUNTRIES.SL = "Sierra Leone";
COUNTRIES.SG = "Singapore";
COUNTRIES.SY = "Siria";
COUNTRIES.SK = "Slovacchia";
COUNTRIES.SI = "Slovenia";
COUNTRIES.SO = "Somalia";
COUNTRIES.LK = "Sri Lanka";
COUNTRIES.FM = "Stati Federati della Micronesia";
COUNTRIES.US = "Stati Uniti d'America";
COUNTRIES.ZA = "Sud Africa";
COUNTRIES.GS = "Sud Georgia e Isole Sandwich";
COUNTRIES.SD = "Sudan";
COUNTRIES.SR = "Suriname";
COUNTRIES.SJ = "Svalbard e Jan Mayen";
COUNTRIES.SE = "Svezia";
COUNTRIES.SZ = "Swaziland";
COUNTRIES.T = null;
COUNTRIES.TJ = "Tagikistan";
COUNTRIES.TH = "Tailandia";
COUNTRIES.TW = "Taiwan";
COUNTRIES.TZ = "Tanzania";
COUNTRIES.IO = "Territori Britannici dell'Oceano Indiano";
COUNTRIES.TF = "Territori Francesi del Sud";
COUNTRIES.PS = "Territori Palestinesi Occupati";
COUNTRIES.TP = "Timor Est";
COUNTRIES.TG = "Togo";
COUNTRIES.TK = "Tokelau";
COUNTRIES.TO = "Tonga";
COUNTRIES.TT = "Trinidad e Tobago";
COUNTRIES.TN = "Tunisia";
COUNTRIES.TR = "Turchia";
COUNTRIES.TM = "Turkmenistan";
COUNTRIES.TV = "Tuvalu";
COUNTRIES.U = null;
COUNTRIES.UA = "Ucraina";
COUNTRIES.UG = "Uganda";
COUNTRIES.HU = "Ungheria";
COUNTRIES.UY = "Uruguay";
COUNTRIES.UZ = "Uzbekistan";
COUNTRIES.V = null;
COUNTRIES.VU = "Vanuatu";
COUNTRIES.VE = "Venezuela";
COUNTRIES.VN = "Vietnam";
COUNTRIES.W = null;
COUNTRIES.WF = "Wallis e Futuna";
COUNTRIES.Y = null;
COUNTRIES.YE = "Yemen";
COUNTRIES.Z = null;
COUNTRIES.ZM = "Zambia";
COUNTRIES.ZW = "Zimbabwe";


export var COUNTRIES_EN = {};
COUNTRIES_EN.IT = "Italy";
COUNTRIES_EN.DE = "Germany";
COUNTRIES_EN.FR = "France";
COUNTRIES_EN.GB = "United Kingdom/Great Britain";
COUNTRIES_EN.BE = "Belgium";
COUNTRIES_EN.NL = "Holland";
COUNTRIES_EN.PT = "Portugal";
COUNTRIES_EN.CZ = "Czech Republic";
COUNTRIES_EN.ES = "Spain";
COUNTRIES_EN.CH = "Swiss";
COUNTRIES_EN.RU = "Russia";
COUNTRIES_EN.CN = "China";
COUNTRIES_EN.JP = "Japan";
COUNTRIES_EN.A = null;
COUNTRIES_EN.AF = "Afghanistan";
COUNTRIES_EN.AL = "Albania";
COUNTRIES_EN.DZ = "Algeria";
COUNTRIES_EN.AD = "Andorra";
COUNTRIES_EN.AO = "Angola";
COUNTRIES_EN.AI = "Eel";
COUNTRIES_EN.AQ = "Antarctica";
COUNTRIES_EN.AG = "Antigua and Barbuda";
COUNTRIES_EN.AN = "Netherlands Antilles";
COUNTRIES_EN.SA = "Saudi Arabia";
COUNTRIES_EN.AR = "Argentina";
COUNTRIES_EN.AM = "Armenia";
COUNTRIES_EN.AW = "Aruba";
COUNTRIES_EN.AU = "Australia";
COUNTRIES_EN.AT = "Austria";
COUNTRIES_EN.AZ = "Azerbaijan";
COUNTRIES_EN.B = null;
COUNTRIES_EN.BS = "Bahamas";
COUNTRIES_EN.BH = "Bahrain";
COUNTRIES_EN.BD = "Bangladesh";
COUNTRIES_EN.BB = "Barbados";
COUNTRIES_EN.BZ = "Belize";
COUNTRIES_EN.BJ = "Benin";
COUNTRIES_EN.BM = "Bermuda";
COUNTRIES_EN.BY = "Belarus";
COUNTRIES_EN.BT = "Bhutan";
COUNTRIES_EN.BO = "Bolivia";
COUNTRIES_EN.BA = "Bosnia Herzegovina";
COUNTRIES_EN.BW = "Botswana";
COUNTRIES_EN.BR = "Brazil";
COUNTRIES_EN.BN = "Brunei Darussalam";
COUNTRIES_EN.BG = "Bulgaria";
COUNTRIES_EN.BF = "Burkina Faso";
COUNTRIES_EN.BI = "Burundi";
COUNTRIES_EN.C = null;
COUNTRIES_EN.KH = "Cambodia";
COUNTRIES_EN.CM = "Cameroon";
COUNTRIES_EN.CA = "Canada";
COUNTRIES_EN.CV = "Cape Verde";
COUNTRIES_EN.TD = "Chad";
COUNTRIES_EN.CL = "Chile";
COUNTRIES_EN.CY = "Cyprus";
COUNTRIES_EN.VA = "Vatican CITY";
COUNTRIES_EN.CO = "Colombia";
COUNTRIES_EN.KM = "Comoros";
COUNTRIES_EN.KP = "North Korea";
COUNTRIES_EN.KR = "South Korea";
COUNTRIES_EN.CR = "Costa Rica";
COUNTRIES_EN.CI = "Ivory Coast";
COUNTRIES_EN.HR = "Croatia";
COUNTRIES_EN.CU = "Cuba";
COUNTRIES_EN.D = null;
COUNTRIES_EN.DK = "Denmark";
COUNTRIES_EN.DM = "Dominica";
COUNTRIES_EN.E = null;
COUNTRIES_EN.EC = "Ecuador";
COUNTRIES_EN.EG = "Egypt";
COUNTRIES_EN.IE = "Eire";
COUNTRIES_EN.SV = "El Salvador";
COUNTRIES_EN.AE = "United Arab Emirates";
COUNTRIES_EN.ER = "Eritrea";
COUNTRIES_EN.EE = "Estonia";
COUNTRIES_EN.ET = "Ethiopia";
COUNTRIES_EN.F = null;
COUNTRIES_EN.FJ = "Fiji";
COUNTRIES_EN.PH = "Philippines";
COUNTRIES_EN.FI = "Finland";
COUNTRIES_EN.G = null;
COUNTRIES_EN.GA = "Gabon";
COUNTRIES_EN.GM = "Gambia";
COUNTRIES_EN.GE = "Georgia";
COUNTRIES_EN.GH = "Ghana";
COUNTRIES_EN.JM = "Jamaica";
COUNTRIES_EN.GI = "Gibraltar";
COUNTRIES_EN.DJ = "Djibouti";
COUNTRIES_EN.JO = "Jordan";
COUNTRIES_EN.GR = "Greece";
COUNTRIES_EN.GD = "Grenada";
COUNTRIES_EN.GL = "Greenland";
COUNTRIES_EN.GP = "Guadeloupe";
COUNTRIES_EN.GU = "Guam";
COUNTRIES_EN.GT = "Guatemala";
COUNTRIES_EN.GG = "Guernsey";
COUNTRIES_EN.GN = "Guinea";
COUNTRIES_EN.GW = "Guinea-Bissau";
COUNTRIES_EN.GQ = "Equatorial Guinea";
COUNTRIES_EN.GY = "Guyana";
COUNTRIES_EN.GF = "French Guiana";
COUNTRIES_EN.H = null;
COUNTRIES_EN.HT = "Haiti";
COUNTRIES_EN.HN = "Honduras";
COUNTRIES_EN.HK = "Hong Kong";
COUNTRIES_EN.I = null;
COUNTRIES_EN.IN = "India";
COUNTRIES_EN.ID = "Indonesia";
COUNTRIES_EN.IR = "Iran";
COUNTRIES_EN.IQ = "Iraq";
COUNTRIES_EN.BV = "Bouvet Island";
COUNTRIES_EN.CX = "Christmas Island";
COUNTRIES_EN.HM = "Heard Island and McDonald Islands";
COUNTRIES_EN.KY = "Cayman Islands";
COUNTRIES_EN.CC = "Cocos Islands";
COUNTRIES_EN.CK = "Cook Islands";
COUNTRIES_EN.FK = "Falkland Islands";
COUNTRIES_EN.FO = "Faroe Islands";
COUNTRIES_EN.MH = "Marshall Islands";
COUNTRIES_EN.MP = "Northern Mariana Islands";
COUNTRIES_EN.UM = "United States Minor Islands";
COUNTRIES_EN.NF = "Norfolk Island";
COUNTRIES_EN.SB = "Solomon Islands";
COUNTRIES_EN.TC = "Turks and Caicos Islands";
COUNTRIES_EN.VI = "US Virgin Islands";
COUNTRIES_EN.VG = "British Virgin Islands";
COUNTRIES_EN.IL = "Israel";
COUNTRIES_EN.IS = "Iceland";
COUNTRIES_EN.K = null;
COUNTRIES_EN.KZ = "Kazakhstan";
COUNTRIES_EN.KE = "Kenya";
COUNTRIES_EN.KG = "Kyrgyzstan";
COUNTRIES_EN.KI = "Kiribati";
COUNTRIES_EN.XK = "Kosovo";
COUNTRIES_EN.KW = "Kuwait";
COUNTRIES_EN.L = null;
COUNTRIES_EN.LA = "Laos";
COUNTRIES_EN.LV = "Latvia";
COUNTRIES_EN.LS = "Lesotho";
COUNTRIES_EN.LB = "Lebanon";
COUNTRIES_EN.LR = "Liberia";
COUNTRIES_EN.LY = "Libya";
COUNTRIES_EN.LI = "Liechtenstein";
COUNTRIES_EN.LT = "Lithuania";
COUNTRIES_EN.LU = "Luxembourg";
COUNTRIES_EN.M = null;
COUNTRIES_EN.MO = "Macau";
COUNTRIES_EN.MK = "Fruit salad";
COUNTRIES_EN.MG = "Madagascar";
COUNTRIES_EN.MW = "Malawi";
COUNTRIES_EN.MV = "Maldives";
COUNTRIES_EN.MY = "Malaysia";
COUNTRIES_EN.ML = "Evils";
COUNTRIES_EN.MT = "Malta";
COUNTRIES_EN.MA = "Morocco";
COUNTRIES_EN.MQ = "Martinique";
COUNTRIES_EN.MR = "Mauritania";
COUNTRIES_EN.MU = "Maurizius";
COUNTRIES_EN.YT = "Mayotte";
COUNTRIES_EN.MX = "Mexico";
COUNTRIES_EN.MD = "Moldova";
COUNTRIES_EN.MC = "Monk";
COUNTRIES_EN.MN = "Mongolia";
COUNTRIES_EN.ME = "Montenegro";
COUNTRIES_EN.MS = "Montserrat";
COUNTRIES_EN.MZ = "Mozambique";
COUNTRIES_EN.MM = "Myanmar";
COUNTRIES_EN.N = null;
COUNTRIES_EN.NA = "Namibia";
COUNTRIES_EN.NR = "Nauru";
COUNTRIES_EN.NP = "Nepal";
COUNTRIES_EN.NI = "Nicaragua";
COUNTRIES_EN.NE = "Niger";
COUNTRIES_EN.NG = "Nigeria";
COUNTRIES_EN.NU = "Niue";
COUNTRIES_EN.NO = "Norway";
COUNTRIES_EN.NC = "New Caledonia";
COUNTRIES_EN.NZ = "New Zeland";
COUNTRIES_EN.O = null;
COUNTRIES_EN.OM = "Oman";
COUNTRIES_EN.P = null;
COUNTRIES_EN.PK = "Pakistan";
COUNTRIES_EN.PW = "Palau";
COUNTRIES_EN.PA = "Panama";
COUNTRIES_EN.PG = "Papua New Guinea";
COUNTRIES_EN.PY = "Paraguay";
COUNTRIES_EN.PE = "Peru";
COUNTRIES_EN.PN = "Pitcairn";
COUNTRIES_EN.PF = "French Polynesia";
COUNTRIES_EN.PL = "Poland";
COUNTRIES_EN.PR = "Puerto Rico";
COUNTRIES_EN.Q = null;
COUNTRIES_EN.QA = "Qatar";
COUNTRIES_EN.R = null;
COUNTRIES_EN.CF = "Central African Republic";
COUNTRIES_EN.CG = "Republic of the Congo";
COUNTRIES_EN.CD = "Democratic Republic of Congo";
COUNTRIES_EN.DO = "Dominican Republic";
COUNTRIES_EN.RE = "Reunion";
COUNTRIES_EN.RO = "Romania";
COUNTRIES_EN.RW = "Rwanda";
COUNTRIES_EN.S = null;
COUNTRIES_EN.EH = "Western Sahara";
COUNTRIES_EN.KN = "Saint Kitts and Nevis";
COUNTRIES_EN.PM = "Saint Pierre and Miquelon";
COUNTRIES_EN.VC = "Saint Vincent and the Grenadines";
COUNTRIES_EN.WS = "Samoa";
COUNTRIES_EN.AS = "American Samoa";
COUNTRIES_EN.SM = "San Marino";
COUNTRIES_EN.SH = "Saint Helena";
COUNTRIES_EN.LC = "Saint Lucia";
COUNTRIES_EN.ST = "Sao Tome and Principe";
COUNTRIES_EN.SN = "Senegal";
COUNTRIES_EN.SC = "Seychelles";
COUNTRIES_EN.RS = "Serbia";
COUNTRIES_EN.SL = "Sierra Leone";
COUNTRIES_EN.SG = "Singapore";
COUNTRIES_EN.SY = "Syria";
COUNTRIES_EN.SK = "Slovakia";
COUNTRIES_EN.SI = "Slovenia";
COUNTRIES_EN.SO = "Somalia";
COUNTRIES_EN.LK = "Sri Lanka";
COUNTRIES_EN.FM = "Federated States of Micronesia";
COUNTRIES_EN.US = "United States of America";
COUNTRIES_EN.ZA = "South Africa";
COUNTRIES_EN.GS = "South Georgia and the Sandwich Islands";
COUNTRIES_EN.SD = "Sudan";
COUNTRIES_EN.SR = "Suriname";
COUNTRIES_EN.SJ = "Svalbard and Jan Mayen";
COUNTRIES_EN.SE = "Sweden";
COUNTRIES_EN.SZ = "Swaziland";
COUNTRIES_EN.T = null;
COUNTRIES_EN.TJ = "Tajikistan";
COUNTRIES_EN.TH = "Thailand";
COUNTRIES_EN.TW = "Taiwan";
COUNTRIES_EN.TZ = "Tanzania";
COUNTRIES_EN.IO = "British Indian Ocean Territories";
COUNTRIES_EN.TF = "Southern French Territories";
COUNTRIES_EN.PS = "Occupied Palestinian Territories";
COUNTRIES_EN.TP = "East Timor";
COUNTRIES_EN.TG = "Togo";
COUNTRIES_EN.TK = "Tokelau";
COUNTRIES_EN.TO = "Tonga";
COUNTRIES_EN.TT = "Trinidad and Tobago";
COUNTRIES_EN.TN = "Tunisia";
COUNTRIES_EN.TR = "Türkiye";
COUNTRIES_EN.TM = "Turkmenistan";
COUNTRIES_EN.TV = "Tuvalu";
COUNTRIES_EN.U = null;
COUNTRIES_EN.UA = "Ukraine";
COUNTRIES_EN.UG = "Uganda";
COUNTRIES_EN.HU = "Hungary";
COUNTRIES_EN.UY = "Uruguay";
COUNTRIES_EN.UZ = "Uzbekistan";
COUNTRIES_EN.V = null;
COUNTRIES_EN.VU = "Vanuatu";
COUNTRIES_EN.VE = "Venezuela";
COUNTRIES_EN.VN = "Vietnam";
COUNTRIES_EN.W = null;
COUNTRIES_EN.WF = "Wallis and Futuna";
COUNTRIES_EN.Y = null;
COUNTRIES_EN.YE = "Yemen";
COUNTRIES_EN.Z = null;
COUNTRIES_EN.ZM = "Zambia";
COUNTRIES_EN.ZW = "Zimbabwe";
