import {StorageHelper} from "./storageHelper";
import {LocaleHelper} from "./localeHelper";

export default {
    created () {
        let user = StorageHelper.getUser();
        let userConsumer = StorageHelper.getUserConsumer();
        let defaultLanguage = StorageHelper.getDefaultLanguage();

        if (user && user.language) {
            LocaleHelper.setLanguage(user.language);
        } else if (userConsumer && userConsumer.language) {
            LocaleHelper.setLanguage(userConsumer.language);
        } else if (defaultLanguage) {
            LocaleHelper.setLanguage(defaultLanguage);
        } else {
            let language = navigator.language;
            if (language.includes('it')) {
                StorageHelper.setDefaultLanguage('it');
                LocaleHelper.setLanguage('it');
            } else {
                StorageHelper.setDefaultLanguage('en');
                LocaleHelper.setLanguage('en');
            }
        }

        if (user && user.timezone) {
            LocaleHelper.setTimezone(user.timezone);
        }
    }
}
